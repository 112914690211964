import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { getToken, getUserPoolClient, setMFPUserSettings } from '../../services/api';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';

const Auth = (_props) => {
    const location = useLocation();
    const history = useHistory();

    const [_user, setUser] = useLocalStorage('user', '');
    const [_tenant, setTenant] = useLocalStorage('tenant', undefined);
    const [_accessToken, setAccessToken] = useLocalStorage('accessToken', undefined);
    const [_idToken, setIdToken] = useLocalStorage('idToken', undefined);
    const [_refreshToken, setRefreshToken] = useLocalStorage('refreshToken', undefined);
    const [_client, setClient] = useLocalStorage('client', undefined);
    const [stratus] = useLocalStorage("stratus", false);

    useEffect(() => {
        async function handleLogin() {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');
            const state = params.get('state');
            const paramState = window.atob(decodeURIComponent(escape(state)));
            const [pPoolId, pDomain, pClientID, pCallback, pRegion, tenantId, tenantName] = paramState.split('|', 7);
            const client = await getUserPoolClient(pPoolId, pClientID, pRegion);
            const clientSecret = client.client.ClientSecret
            const token = await getToken(pDomain, pRegion, code, pClientID, clientSecret, pCallback);
            const accessToken = token.access_token;
            const idToken = token.id_token;
            const refreshToken = token.refresh_token;
            // Get username. Username is different for SSO and Cognito users
            const decodedIdToken = jwtDecode(idToken);
            const decodedAccessToken = jwtDecode(accessToken);
            const user = decodedIdToken.email || decodedAccessToken.username
            setUser(user);
            setTenant({ id: tenantId, name: tenantName, slug: pDomain, region: pRegion });
            setIdToken(idToken);
            setAccessToken(accessToken);
            setRefreshToken(refreshToken);
            setClient(client.client);
            let appId = '0A024AC9';
            if (stratus) {
                appId = '0A024ADM';
            }
            await setMFPUserSettings({
                accessToken,
                idToken,
                refreshToken,
                user,
                client: client.client,
                tenant: { id: tenantId, name: tenantName, slug: pDomain, region: pRegion }
            }, appId);
            // Clear MarketPlace session
            /*
            const redirectUrlBase = `https://${pDomain}.auth.${pRegion}.amazoncognito.com/logout`
            const logoutParams = new URLSearchParams({
                client_id: client.client.ClientId,
                logout_uri: `${window.location.protocol}//${window.location.host}`,
            })
            const redirectUrl = `${redirectUrlBase}?${logoutParams.toString()}`;

            window.location = redirectUrl
            */
           history.push('/');
        }
        handleLogin();
    // eslint-disable-next-line
    }, [history, location.search]);

    return (
        <></>
    )
}

export default Auth